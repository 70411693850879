import { useState, useEffect } from 'react';
import { ResumeService } from 'src/services/api/ResumeService';
import { UserService } from 'src/services/api/UserService';

export const useGenericOTP = (value, type, toast) => {
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [verified, setVerified] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isOTPLoading, setIsOTPLoading] = useState(false);
  const [isVerifyingOTP, setIsVerifyingOTP] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [resendTimeout, setResendTimeout] = useState(120);
  const [canResend, setCanResend] = useState(false);

  const service = type === 'contact' ? ResumeService : UserService;

  useEffect(() => {
    let timer;
    if (isOTPSent && resendTimeout > 0) {
      timer = setInterval(() => {
        setResendTimeout((prev) => prev - 1);
      }, 1000);
    }
    if (resendTimeout === 0) {
      setCanResend(true);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isOTPSent, resendTimeout]);

  const getUserCid = () => {
    try {
      const userData = JSON.parse(localStorage.getItem('userData'));
      return userData?.cid;
    } catch (error) {
      console.error('Error getting user id:', error);
      return null;
    }
  };

  const handleSendOTP = async (action = 'SEND') => {
    try {
      setIsOTPLoading(true);
      const data =
        type === 'contact'
          ? { phone: value }
          : {
              email: value,
              id: getUserCid(),
            };

      const response = await (action === 'SEND'
        ? service.sendOtp(data)
        : service.resendOtp(data));

      if (response.success) {
        setIsOTPSent(true);
        setShowOTPInput(true);
        if (action === 'RESEND') {
          setCanResend(false);
          setResendTimeout(30);
        }
        toast({
          title: `OTP ${action === 'SEND' ? 'Sent' : 'Resent'} Successfully`,
          description: `Please check your ${type} for the OTP`,
        });
      } else {
        throw new Error(response.message || 'Failed to get OTP');
      }
    } catch (error) {
      console.error('OTP sending error:', error);
      toast({
        title: 'Failed to get OTP',
        description: error.message || 'Please try again later',
        variant: 'destructive',
      });
      setIsOTPSent(false);
      setShowOTPInput(false);
    } finally {
      setIsOTPLoading(false);
    }
  };

  const handleVerifyOTP = async (otp) => {
    if (otp.length === 4) {
      try {
        setIsVerifyingOTP(true);
        setOtpError('');
        const data =
          type === 'contact'
            ? { phone: value, otp }
            : { email: value, otp, id: getUserCid() };

        const response = await service.verifyOtp(data);

        if (response.success) {
          setVerified(true);
          setShowOTPInput(false);
          toast({
            title: `${type === 'contact' ? 'Phone' : 'Email'} Verified`,
            description: `Your ${type} has been verified successfully.`,
          });
        } else {
          throw new Error(response.message || 'Invalid OTP');
        }
      } catch (error) {
        console.error('OTP verification error:', error);
        setVerified(false);
        setOtpError(error.message || 'Failed to verify OTP. Please try again.');
        toast({
          title: 'Verification Failed',
          description: error.message || 'Please check your OTP and try again',
          variant: 'destructive',
        });
      } finally {
        setIsVerifyingOTP(false);
      }
    }
  };

  return {
    showOTPInput,
    verified,
    isOTPSent,
    isOTPLoading,
    isVerifyingOTP,
    otpError,
    resendTimeout,
    canResend,
    handleSendOTP,
    handleVerifyOTP,
  };
};
