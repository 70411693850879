import { ResumeService } from 'src/services/api/ResumeService';
import { defaultValues } from './constants';

const createResumeApi = (config = {}) => {
  const {
    isProtected = false, // Flag to determine if it's protected route
    candidateId = null, // Optional candidate ID for protected route
    token = null, // Optional token for public route
  } = config;

  return {
    async fetchResumeData() {
      try {
        // Choose the appropriate service method based on configuration
        let response;
        if (isProtected) {
          if (!candidateId) {
            throw new Error('Candidate ID is required for protected route');
          }
          response = await ResumeService.getProfile(candidateId);
        } else {
          if (!token) {
            throw new Error('Token is required for public route');
          }
          response = await ResumeService.getResumeProfile(token);
        }

        if (!response.success) {
          throw new Error('Failed to fetch resume data');
        }
        const candId = response.data.candidate_id;
        const profileData = response.data.profile_data;
        const personalInfo = profileData.personal_information;
        const address = personalInfo.address || {};
        const masterData = profileData.master_data || {};
        const mappedEducations = profileData.educational_details?.length
          ? profileData.educational_details.map((edu) => {
              const isGPA = edu.score_type === 'GPA';
              let fieldsOfStudyArray = [];
              if (
                edu.qualification_type === 'TENTH' ||
                edu.qualification_type === 'TWELFTH'
              ) {
                fieldsOfStudyArray =
                  masterData?.fields_of_study?.secondary || [];
              } else {
                fieldsOfStudyArray = masterData?.fields_of_study?.degree || [];
              }

              // Find field of study text from the master data
              const fieldOfStudyText =
                fieldsOfStudyArray.find(
                  (field) => field.id === edu.field_of_study_id
                )?.name || '';

              return {
                institution: edu.institution || '',
                qualification: edu.qualification_type || '',
                graduationYear: edu.graduation_year?.toString() || '',
                fieldsOfStudy: edu.field_of_study_id?.toString() || '',
                fieldOfStudyVal: fieldOfStudyText,
                calculatedScore: edu.calculated_score || '',
                gradeType:
                  !edu.score_type || edu.score_type === 'MARKS'
                    ? 'marks'
                    : 'gpa',
                // For marks
                maxMarks: !isGPA ? edu.max_score?.toString() || '' : '',
                earnedMarks: !isGPA ? edu.actual_score?.toString() || '' : '',
                // For GPA - Don't append '.0'
                maxGPA: isGPA ? edu.max_score?.toString() || '' : '',
                earnedGPA: isGPA ? edu.actual_score?.toString() || '' : '',
                board:
                  edu.qualification_type === 'TENTH' ||
                  edu.qualification_type === 'TWELFTH'
                    ? edu.board_type
                    : '',
                university:
                  edu.qualification_type !== 'TENTH' &&
                  edu.qualification_type !== 'TWELFTH'
                    ? edu.university
                    : '',
              };
            })
          : defaultValues.educations;

        const mappedData = {
          ...defaultValues,
          firstname: personalInfo.first_name || '',
          middlename: personalInfo.middle_name || '',
          lastname: personalInfo.last_name || '',
          candidateId: candId || '',
          email: personalInfo.email || '',
          contact: personalInfo.phone || '',
          dob: personalInfo.dob || '',
          fathersname: personalInfo.father_name || '',
          addressLine1: address.address_line_1 || '',
          street: address.street || '',
          city: address.city || '',
          state: address.state || '',
          zipCode: address.zip_code || '',
          educations: mappedEducations,
          workExperiences: profileData.work_experiences?.length
            ? profileData.work_experiences.map((exp) => {
                const [fromMonth, fromYear] = (exp.from_date || '').split('/');
                const [toMonth, toYear] = (exp.to_date || '').split('/');

                return {
                  company: exp.company_name || '',
                  role: exp.role || '',
                  employment_period: {
                    startMonth: fromMonth || '',
                    startYear: fromYear || '',
                    endMonth: toMonth || '',
                    endYear: toYear || '',
                  },
                };
              })
            : defaultValues.workExperiences,
          skills: profileData.skills?.map((id) => id.toString()) || [],
          otherSkills: profileData?.other_details?.other_skills,
          certifications:
            profileData.certifications?.map((id) => id.toString()) || [],
          otherCertifications: profileData?.other_details?.other_certifications,
          masterData: {
            skills: masterData?.skills?.['1']?.skills || [],
            certifications:
              masterData?.certifications?.['1']?.certifications || [],
            fieldsofstudies: masterData?.fields_of_study?.degree || [],
            fieldsofstudieshsc: masterData?.fields_of_study?.secondary || [],
            states: masterData?.states || [],
            locations: masterData?.locations || [],
          },
        };

        return mappedData;
      } catch (error) {
        console.error('Error fetching resume data:', error);
        throw error;
      }
    },

    async updateResume(formData) {
      try {
        const transformedEducation = formData.educations.map((edu) => ({
          institution: edu.institution,
          graduation_year: parseInt(edu.graduationYear, 10),
          qualification_type: edu.qualification,
          // field_of_study_id: fieldsOfStudyMap[edu.fieldsOfStudy],
          field_of_study_id:
            edu.qualification === 'TENTH'
              ? null
              : parseInt(edu.fieldsOfStudy, 10),
          score_type: edu.gradeType.toUpperCase(),
          max_score:
            edu.gradeType.toLowerCase() === 'marks'
              ? parseFloat(edu.maxMarks)
              : parseFloat(edu.maxGPA),
          actual_score:
            edu.gradeType.toLowerCase() === 'marks'
              ? parseFloat(edu.earnedMarks)
              : parseFloat(edu.earnedGPA),
          board_type:
            edu.qualification === 'TENTH' || edu.qualification === 'TWELFTH'
              ? edu.board
              : null,
          university:
            edu.qualification !== 'TENTH' && edu.qualification !== 'TWELFTH'
              ? edu.university
              : null,
        }));

        const transformedData = {
          first_name: formData.firstname,
          middle_name: formData.middlename,
          last_name: formData.lastname,
          email: formData.email,
          phone: formData.contact,
          dob: formData.dob,
          father_name: formData.fathersname,
          address_line_1: formData.addressLine1,
          street: formData.street,
          city_id: formData.city,
          state_id: formData.state,
          zip_code: formData.zipCode,
          educational_details: transformedEducation,
          work_experiences: formData.workExperiences.map((exp) => ({
            company_name: exp.company,
            role: exp.role,
            from_date: `${exp.employment_period.startMonth}/${exp.employment_period.startYear}`,
            to_date: `${exp.employment_period.endMonth}/${exp.employment_period.endYear}`,
          })),
          other_skills: formData.otherSkills,
          other_certifications: formData.otherCertifications,
          skills: formData.skills?.length
            ? formData.skills.map((id) => parseInt(id, 10))
            : [],
          certifications: formData.certifications?.length
            ? formData.certifications.map((id) => parseInt(id, 10))
            : [],
        };

        // Use the same updateProfile endpoint for both cases
        const response = await ResumeService.updateProfile(
          isProtected ? candidateId : formData.candidateId,
          transformedData
        );

        if (!response.success) {
          throw new Error(response.message || 'Failed to update resume');
        }

        return response;
      } catch (error) {
        console.error('Error updating resume:', error);
        throw error;
      }
    },
  };
};

export default createResumeApi;
