export const createEndpoint = (template, params = {}) => {
  let endpoint = template;

  // Replace URL parameters (:param)
  Object.entries(params).forEach(([key, value]) => {
    endpoint = endpoint.replace(`:${key}`, value);
  });

  // Replace query parameters ({{param}})
  Object.entries(params).forEach(([key, value]) => {
    const placeholder = `{{${key}}}`;
    if (endpoint.includes(placeholder)) {
      endpoint = endpoint.replace(placeholder, encodeURIComponent(value));
    }
  });

  return endpoint;
};

export const API_ENDPOINTS = {
  AUTH: {
    VERIFY: '/auth/verify',
    REFRESH: '/candidates/auth/refresh-token',
  },
  USER: {
    GOOGLEAUTH: '/candidates/google/url',
    GOOGLEAUTHSIGNUP: '/candidates/google/url?cid={{cid}}',
    GOOGLEAUTHCALLBACK:
      '/candidates/google/callback?code={{code}}&state={{state}}',
    REGISTER: '/candidates/register-candidate',
    LOGIN: '/candidates/auth/login',
    LOGOUT: '/candidates/auth/logout',
    PROFILE: '/user/profile',
    UPDATE: '/user/update',
    ACTIVATE: '/auth/verify-email/:token',
    SENDOTP: '/candidates/request-email-otp',
    VERIFYOTP: '/candidates/verify-email-otp',
    RESENDOTP: '/candidates/resend-email-otp',
    FORGOTPASSWORD: '/auth/forgot-password',
    RESETPASSWORD: '/auth/reset-password',
    CHANGEPASSWORD: '/auth/change-password',
  },
  RESUME: {
    SENDOTP: '/candidates/request-otp',
    VERIFYOTP: '/candidates/verify-otp',
    RESENDOTP: '/candidates/resend-otp',
    REQUESTSIGNEDURL: '/util/generate-upload-url',
    FORMSUBMISSION: '/candidates/submit-basic-profile',
    GETPROFILE: '/candidates/:id/profile',
    UPDATEPROFILE: '/candidates/:id/completeprofile',
    RESUMEPROFILE: '/candidates/resume-profile/verify/:token',
  },
  // Add more endpoints as needed
};
