// import axios from 'axios';
import api from '../axios.config';
import { API_ENDPOINTS } from './endpoints';

export const AuthService = {
  refreshToken: async (data) => {
    const response = await api.post(API_ENDPOINTS.AUTH.REFRESH, data);
    return response;
  },
};
