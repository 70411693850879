import React from 'react';

const ImageWithFallback = ({
  src,
  fallback,
  alt,
  className = '',
  width,
  height,
  loading,
  onClick,
  onLoad,
  onError,
  style,
}) => (
  <picture>
    <source srcSet={src} type="image/webp" />
    {onClick ? (
      <button
        type="button"
        onClick={onClick}
        className={className}
        style={style}
      >
        <img
          src={fallback}
          alt={alt}
          width={width}
          height={height}
          loading={loading}
          onLoad={onLoad}
          onError={onError}
          className="w-full h-full"
        />
      </button>
    ) : (
      <img
        src={fallback}
        alt={alt}
        className={className}
        width={width}
        height={height}
        loading={loading}
        onLoad={onLoad}
        onError={onError}
        style={style}
      />
    )}
  </picture>
);

export default ImageWithFallback;
