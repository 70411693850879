// import axios from 'axios';

// const api = axios.create({
//   baseURL: process.env.REACT_APP_API_BASE_URL,
//   timeout: 10000,
// });

// // Get token from localStorage
// const getToken = () => localStorage.getItem('token');

// // Refresh token function
// const refreshToken = async () => {
//   try {
//     const response = await axios.post(
//       `${process.env.REACT_APP_API_BASE_URL}/auth/refresh-token`,
//       {
//         refresh_token: localStorage.getItem('refreshToken'),
//       }
//     );
//     if (response.data.token) {
//       localStorage.setItem('token', response.data.token);
//       if (response.data.refresh_token) {
//         localStorage.setItem('refreshToken', response.data.refresh_token);
//       }
//       return response.data.token;
//     }
//     throw new Error('No token received');
//   } catch (error) {
//     localStorage.removeItem('token');
//     localStorage.removeItem('refreshToken');
//     window.location.href = '/login';
//     throw error;
//   }
// };

// // Request interceptor
// api.interceptors.request.use(
//   async (config) => {
//     // Create new config object instead of modifying the parameter
//     const newConfig = { ...config };
//     const token = getToken();

//     if (token) {
//       newConfig.headers = {
//         ...newConfig.headers,
//         Authorization: `Bearer ${token}`,
//       };
//     }
//     return newConfig;
//   },
//   (error) => Promise.reject(error)
// );

// // Response interceptor
// api.interceptors.response.use(
//   (response) => response.data,
//   async (error) => {
//     const originalRequest = error.config;

//     // Handle 401 (Unauthorized)
//     if (error.response?.status === 401 && !originalRequest.hasRetried) {
//       originalRequest.hasRetried = true;

//       try {
//         // Try to refresh the token
//         const newToken = await refreshToken();
//         // Retry the original request with the new token
//         originalRequest.headers.Authorization = `Bearer ${newToken}`;
//         return api(originalRequest);
//       } catch (refreshError) {
//         // If refresh token fails, redirect to login
//         localStorage.removeItem('token');
//         localStorage.removeItem('refreshToken');
//         window.location.href = '/login';
//         return Promise.reject(refreshError);
//       }
//     }

//     // Handle other errors
//     const errorMessage = error.response?.data || error.message;

//     // You can add custom error handling here
//     switch (error.response?.status) {
//       case 400:
//         console.error('Bad Request:', errorMessage);
//         break;
//       case 403:
//         console.error('Forbidden:', errorMessage);
//         break;
//       case 404:
//         console.error('Not Found:', errorMessage);
//         break;
//       case 500:
//         console.error('Server Error:', errorMessage);
//         break;
//       default:
//         console.error('Error:', errorMessage);
//     }

//     return Promise.reject(errorMessage);
//   }
// );

// export default api;
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { AuthService } from 'src/services/api/AuthService';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 10000,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const getToken = () => localStorage.getItem('token');

const isTokenExpired = (token) => {
  if (!token) return true;
  try {
    const decoded = jwtDecode(token);
    return decoded.exp * 1000 < Date.now();
  } catch {
    return true;
  }
};
// const refreshToken = async () => {
//   try {
//     const response = await AuthService.refreshToken({
//       refresh_token: localStorage.getItem('refreshToken'),
//     });

//     if (response.data?.data?.tokens?.access_token) {
//       localStorage.setItem('token', response.data.data.tokens.access_token);
//       if (response.data.data.tokens.refresh_token) {
//         localStorage.setItem(
//           'refreshToken',
//           response.data.data.tokens.refresh_token
//         );
//       }
//       return response.data.data.tokens.access_token;
//     }
//     throw new Error('No token received');
//   } catch (error) {
//     localStorage.removeItem('token');
//     localStorage.removeItem('refreshToken');
//     window.location.href = '/login';
//     throw error;
//   }
// };
const refreshToken = async () => {
  try {
    // const response = await axios.post(
    //   `${process.env.REACT_APP_API_BASE_URL}/auth/refresh-token`,
    //   {
    //     refresh_token: localStorage.getItem('refreshToken'),
    //   }
    // );
    const response = await AuthService.refreshToken({
      refresh_token: localStorage.getItem('refreshToken'),
    });

    if (response.data?.data?.tokens?.access_token) {
      localStorage.setItem('token', response.data.data.tokens.access_token);
      if (response.data.data.tokens.refresh_token) {
        localStorage.setItem(
          'refreshToken',
          response.data.data.tokens.refresh_token
        );
      }
      return response.data.data.tokens.access_token;
    }
    throw new Error('No token received');
  } catch (error) {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    window.location.href = '/login';
    throw error;
  }
};

// Request interceptor
api.interceptors.request.use(
  async (config) => {
    const newConfig = { ...config };
    const token = getToken();

    if (
      token &&
      isTokenExpired(token) &&
      !newConfig.url?.includes('refresh-token')
    ) {
      try {
        const newToken = await refreshToken();
        newConfig.headers = {
          ...newConfig.headers,
          Authorization: `Bearer ${newToken}`,
        };
      } catch (error) {
        return Promise.reject(error);
      }
    } else if (token) {
      newConfig.headers = {
        ...newConfig.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return newConfig;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
api.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest.retryAttempt) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return api(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest.retryAttempt = true;
      isRefreshing = true;

      try {
        const newToken = await refreshToken();
        processQueue(null, newToken);
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    const errorMessage = error.response?.data || error.message;

    // Error handling
    switch (error.response?.status) {
      case 400:
        console.error('Bad Request:', errorMessage);
        break;
      case 403:
        console.error('Forbidden:', errorMessage);
        break;
      case 404:
        console.error('Not Found:', errorMessage);
        break;
      case 500:
        console.error('Server Error:', errorMessage);
        break;
      default:
        console.error('Error:', errorMessage);
    }

    return Promise.reject(errorMessage);
  }
);

export default api;
