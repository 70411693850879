import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { Alert, AlertDescription } from 'src/components/ui/alert';
import { Eye, EyeOff } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { UserService } from 'src/services/api/UserService';
import ImageWithFallback from 'src/components/common/ImageWithFallback';
import loginImgJPG from '../../assets/images/login_bg.jpg';
import loginImgWebP from '../../assets/images/login_bg.webp';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get('token');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const validatePassword = (value) => {
    const hasMinLength = value.length >= 8;
    const hasLowerCase = /[a-z]/.test(value);
    const hasUpperCase = /[A-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpecialChar = /[@$!%*?&]/.test(value);

    return (
      hasMinLength &&
      hasLowerCase &&
      hasUpperCase &&
      hasNumber &&
      hasSpecialChar
    );
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);

    setPasswordError('');
    setConfirmPasswordError('');

    if (!value.trim()) {
      setPasswordError('Password is required');
      return;
    }

    if (!validatePassword(value)) {
      setPasswordError(
        'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character'
      );
    }

    if (confirmPassword && value !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match');
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);

    setConfirmPasswordError('');

    if (!value.trim()) {
      setConfirmPasswordError('Please confirm your password');
      return;
    }

    if (value !== password) {
      setConfirmPasswordError('Passwords do not match');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setPasswordError('');
    setConfirmPasswordError('');
    setError('');

    if (!password.trim() || !confirmPassword.trim()) {
      if (!password.trim()) setPasswordError('Password is required');
      if (!confirmPassword.trim())
        setConfirmPasswordError('Please confirm your password');
      return;
    }

    if (!validatePassword(password)) {
      setPasswordError(
        'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character'
      );
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match');
      return;
    }

    try {
      setIsLoading(true);

      const response = await UserService.resetPassword({
        email: resetToken,
        new_password: password,
      });

      if (response.success) {
        navigate('/login', {
          state: {
            message:
              'Password has been reset successfully. Please login with your new password.',
          },
        });
      }
    } catch (err) {
      console.error('Reset password error:', err);
      setError(err.message || 'Failed to reset password. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // If no token is present, show error
  if (!resetToken) {
    return (
      <div className="h-screen bg-gray-100 flex overflow-hidden">
        <div className="hidden md:block w-[68%] relative">
          <div className="absolute inset-0">
            <ImageWithFallback
              src={loginImgWebP}
              fallback={loginImgJPG}
              alt="Login background"
              className="w-full h-full object-cover opacity-30"
              loading="eager"
            />
          </div>
          <div className="absolute inset-0 bg-black bg-opacity-30" />
          <div className="relative z-10 h-full flex flex-col justify-center px-16">
            <h1 className="text-4xl font-bold mb-4 ahDarkBlueText">
              Reset Password
            </h1>
            <p className="font-medium text-gray-800 px-8">
              Invalid or expired reset link. Please request a new password reset
              link.
            </p>
          </div>
        </div>
        <div className="w-full md:w-[32%] flex justify-center items-center p-4 overflow-y-auto">
          <div className="w-full max-w-lg px-6">
            <Alert variant="destructive">
              <AlertDescription>
                Invalid or expired reset link. Please request a new password
                reset.
              </AlertDescription>
            </Alert>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen bg-gray-100 flex overflow-hidden">
      {/* Left Column - Image */}
      <div className="hidden md:block w-[68%] relative">
        <div className="absolute inset-0">
          <ImageWithFallback
            src={loginImgWebP}
            fallback={loginImgJPG}
            alt="Login background"
            className="w-full h-full object-cover opacity-30"
            loading="eager"
          />
        </div>
        <div className="absolute inset-0 bg-black bg-opacity-30" />
        <div className="relative z-10 h-full flex flex-col justify-center px-16">
          <h1 className="text-4xl font-bold mb-4 ahDarkBlueText">
            Reset Password
          </h1>
          <p className="font-medium text-gray-800 px-8">
            Create a new strong password for your account. Make sure it&apos;s
            different from your previous passwords.
          </p>
        </div>
      </div>

      {/* Right Column - Form */}
      <div className="w-full md:w-[32%] flex justify-center items-center p-6 overflow-y-auto">
        <div className="w-full max-w-lg">
          <div className="text-left mb-6">
            <h2 className="text-2xl font-bold mb-2">Reset Your Password</h2>
            <p className="text-gray-600">Please enter your new password</p>
          </div>

          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <TooltipProvider>
                <Tooltip open={showPasswordTooltip}>
                  <TooltipTrigger asChild>
                    <div className="relative">
                      <Input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="New Password"
                        value={password}
                        onChange={handlePasswordChange}
                        onFocus={() => setShowPasswordTooltip(true)}
                        onBlur={() => setShowPasswordTooltip(false)}
                        className={`h-12 ${
                          passwordError ? 'border-red-500' : ''
                        }`}
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                      >
                        {showPassword ? (
                          <EyeOff className="h-4 w-4" />
                        ) : (
                          <Eye className="h-4 w-4" />
                        )}
                      </button>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent side="right" className="p-2">
                    <div className="text-sm text-left">
                      Password must contain:
                      <div className="space-y-1 mt-1">
                        <div>• At least 8 characters</div>
                        <div>• One uppercase letter</div>
                        <div>• One lowercase letter</div>
                        <div>• One number</div>
                        <div>• One special character (@$!%*?&)</div>
                      </div>
                    </div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              {passwordError && (
                <p className="text-sm text-red-500 mt-1 text-left">
                  {passwordError}
                </p>
              )}
            </div>

            <div className="space-y-2">
              <div className="relative">
                <Input
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  className={`h-12 ${
                    confirmPasswordError ? 'border-red-500' : ''
                  }`}
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                >
                  {showConfirmPassword ? (
                    <EyeOff className="h-4 w-4" />
                  ) : (
                    <Eye className="h-4 w-4" />
                  )}
                </button>
              </div>
              {confirmPasswordError && (
                <p className="text-sm text-red-500 mt-1 text-left">
                  {confirmPasswordError}
                </p>
              )}
            </div>

            <Button type="submit" className="w-full h-12" disabled={isLoading}>
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <div className="animate-spin mr-2 h-4 w-4 border-2 border-white border-t-transparent rounded-full" />
                  Resetting Password...
                </div>
              ) : (
                'Reset Password'
              )}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
