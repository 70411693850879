import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import { useToast } from 'src/hooks/use-toast';
import { scrollToError } from 'src/utils/form-utils';
import { PersonalInfoSection } from '../../components/resume/PersonalInfoSection';
import {
  EducationFields,
  AddEducationButton,
} from '../../components/resume/EducationFields';
import {
  WorkExperienceFields,
  AddExperienceButton,
} from '../../components/resume/WorkExperienceFields';
import { Section, FormActions } from '../../components/resume/common';
import { SkillsSection } from '../../components/resume/SkillsSection';
import { CertificationsSection } from '../../components/resume/CertificationsSection';
import { defaultValues } from '../../components/resume/constants';
import createResumeApi from '../../components/resume/resumeApi';

const validationRules = {
  // Personal Info
  firstname: { required: 'First name is required' },
  lastname: { required: 'Last name is required' },
  email: {
    required: 'Email is required',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Invalid email address',
    },
  },
  contact: {
    required: 'Phone number is required',
    pattern: {
      value: /^\+?[\d\s-]{10,}$/,
      message: 'Invalid phone number',
    },
  },
  dob: { required: 'Date of birth is required' },
  fathersname: { required: "Father's name is required" },
  state: { required: 'State is required' },
  zipCode: { required: 'ZIP code is required' },
};
const educationValidation = {
  'educations.*.institution': { required: 'Institution name is required' },
  'educations.*.qualification': { required: 'Qualification is required' },
  'educations.*.graduationYear': { required: 'Year of passing is required' },
  'educations.*.fieldsOfStudy': { required: 'Field of study is required' },
  'educations.*.maxGPA': { required: 'Max GPA is required' },
  'educations.*.earnedGPA': { required: 'Earned GPA is required' },
};
// Constants
const INITIAL_MASTER_DATA = {
  skills: [],
  certifications: [],
  fieldsofstudies: [],
  fieldsofstudieshsc: [],
  city: [],
  state: [],
};

// Utility Functions
const getUserId = () => {
  try {
    const userData = JSON.parse(localStorage.getItem('user'));

    return userData?.cid || null;
  } catch (error) {
    console.error('Error getting user ID:', error);
    return null;
  }
};

const getEmptyEducation = () => ({
  institution: '',
  qualification: '',
  graduationYear: '',
  fieldsOfStudy: '',
  gradeType: 'MARKS',
  maxMarks: '',
  earnedMarks: '',
  maxGPA: '',
  earnedGPA: '',
  board: '',
  university: '',
});

const getEmptyWorkExperience = () => ({
  company: '',
  role: '',
  employment_period: {
    startMonth: '',
    startYear: '',
    endMonth: '',
    endYear: '',
  },
});

const generateKeys = {
  education: (edu, index) =>
    `education-${edu.institution || ''}-${edu.graduationYear || ''}-${index}`,
  workExperience: (exp, index) =>
    `work-${exp.company || ''}-${exp.role || ''}-${index}`,
};

export function SubmitResume() {
  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState(defaultValues);
  const [masterData, setMasterData] = useState(INITIAL_MASTER_DATA);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: location.state?.formData || defaultValues,
    mode: 'onBlur',
    rules: {
      ...validationRules,
      ...educationValidation,
    },
  });

  // API Setup
  const resumeApi = useMemo(() => {
    const candidateId = getUserId();

    return candidateId
      ? createResumeApi({
          isProtected: true,
          candidateId,
        })
      : null;
  }, []);

  // Education Handlers
  const handleAddEducation = () => {
    const currentValues = watch();
    const updatedEducations = [
      ...currentValues.educations,
      getEmptyEducation(),
    ];

    reset({
      ...currentValues,
      educations: updatedEducations,
    });
    setFormData((prev) => ({
      ...prev,
      educations: updatedEducations,
    }));
  };

  const handleRemoveEducation = (index) => {
    const currentValues = watch();
    const updatedEducations = currentValues.educations.filter(
      (_, i) => i !== index
    );

    if (updatedEducations.length === 0) {
      updatedEducations.push(getEmptyEducation());
    }

    reset({
      ...currentValues,
      educations: updatedEducations,
    });
    setFormData((prev) => ({
      ...prev,
      educations: updatedEducations,
    }));
  };

  // Work Experience Handlers
  const handleAddWorkExperience = () => {
    const currentValues = watch();
    const updatedWorkExperiences = [
      ...(currentValues.workExperiences || []),
      getEmptyWorkExperience(),
    ];

    reset({
      ...currentValues,
      workExperiences: updatedWorkExperiences,
    });
    setFormData((prev) => ({
      ...prev,
      workExperiences: updatedWorkExperiences,
    }));
  };

  const handleRemoveWorkExperience = (index) => {
    const currentValues = watch();
    const updatedWorkExperiences = currentValues.workExperiences.filter(
      (_, i) => i !== index
    );

    if (updatedWorkExperiences.length === 0) {
      updatedWorkExperiences.push(getEmptyWorkExperience());
    }

    reset({
      ...currentValues,
      workExperiences: updatedWorkExperiences,
    });
    setFormData((prev) => ({
      ...prev,
      workExperiences: updatedWorkExperiences,
    }));
  };

  const loadFormData = async () => {
    try {
      setIsLoading(true);
      const mappedData = await resumeApi.fetchResumeData();

      const initialData = {
        ...mappedData,
        educations:
          mappedData.educations?.length > 0
            ? mappedData.educations
            : [getEmptyEducation()],
        workExperiences:
          mappedData.workExperiences?.length > 0
            ? mappedData.workExperiences
            : [getEmptyWorkExperience()],
      };

      setFormData(initialData);
      setMasterData(mappedData.masterData || INITIAL_MASTER_DATA);
      reset(initialData);
    } catch (error) {
      console.error('Error loading data:', error);
      const defaultData = {
        ...defaultValues,
        educations: [getEmptyEducation()],
        workExperiences: [getEmptyWorkExperience()],
      };
      setFormData(defaultData);
      setMasterData(INITIAL_MASTER_DATA);
      reset(defaultData);
      toast({
        title: 'Error loading resume data',
        description: error.message || 'Please try again later',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const validateRequiredQualifications = (educations) => {
    // Count occurrences of each qualification
    const qualificationCounts = educations.reduce((acc, edu) => {
      if (edu.qualification) {
        acc[edu.qualification] = (acc[edu.qualification] || 0) + 1;
      }
      return acc;
    }, {});

    const requiredQualifications = [
      { value: 'TENTH', label: 'Secondary' },
      { value: 'TWELFTH', label: 'Higher Secondary' },
      { value: 'DEGREE', label: "Bachelor's Degree" },
    ];

    const missingQualifications = requiredQualifications.filter(
      (qual) => !qualificationCounts[qual.value]
    );

    if (missingQualifications.length > 0) {
      const missingLabels = missingQualifications.map((qual) => qual.label);
      return {
        isValid: false,
        message: `Please add ${missingLabels.join(', ')} ${
          missingLabels.length > 1 ? 'qualifications' : 'qualification'
        } to proceed`,
      };
    }

    return { isValid: true };
  };

  // Add this before handleSubmitForm
  const validateForm = (data) => {
    const errs = {};

    // Validate education qualifications
    const qualificationValidation = validateRequiredQualifications(
      data.educations
    );
    if (!qualificationValidation.isValid) {
      errs.educations = {
        qualification: {
          type: 'manual',
          message: qualificationValidation.message,
        },
      };
    }

    // Check for duplicate qualifications
    const qualificationCounts = data.educations.reduce((acc, edu) => {
      if (edu.qualification) {
        acc[edu.qualification] = (acc[edu.qualification] || 0) + 1;
      }
      return acc;
    }, {});

    if (qualificationCounts.TENTH > 1 || qualificationCounts.TWELFTH > 1) {
      if (!errors.educations) errors.educations = {};
      errors.educations.qualification = {
        type: 'manual',
        message: 'Duplicate qualifications are not allowed',
      };
    }

    return errors;
  };

  // Update handleSubmitForm
  const handleSubmitForm = async (data) => {
    try {
      // Validate form
      const validationErrors = validateForm(data);
      if (Object.keys(validationErrors).length > 0) {
        scrollToError(validationErrors);
        return;
      }

      // API authentication check
      if (!resumeApi) {
        toast({
          title: 'Authentication required',
          description: 'Please log in to continue',
          variant: 'destructive',
        });
        navigate('/login');
        return;
      }

      setIsLoading(true);

      const filteredWorkExperiences = data.workExperiences.filter(
        (exp) =>
          exp.company ||
          exp.role ||
          exp.employment_period.startMonth ||
          exp.employment_period.startYear ||
          exp.employment_period.endMonth ||
          exp.employment_period.endYear
      );

      await resumeApi.updateResume({
        ...data,
        workExperiences:
          filteredWorkExperiences.length > 0 ? filteredWorkExperiences : [],
      });

      toast({ title: 'Resume updated successfully' });
      navigate('/resume-preview');
    } catch (error) {
      toast({
        title: 'Error updating resume',
        description: error.message || 'Please try again later',
        variant: 'destructive',
      });

      // If it's a validation error, scroll to the error
      if (error.validationErrors) {
        scrollToError(error.validationErrors);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Initial Data Load
  useEffect(() => {
    if (!resumeApi) {
      setIsLoading(false);
      toast({
        title: 'Authentication required',
        description: 'Please log in to continue',
        variant: 'destructive',
      });
      // navigate('/login');
      return;
    }

    loadFormData();
  }, [resumeApi, reset, toast, navigate]);

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
        <p className="mt-4 text-sm text-gray-600">Loading resume data...</p>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center bg-gray-100">
      <div className="container mx-auto px-4 py-8 pb-24 md:pb-8 max-w-4xl">
        <h1 className="text-2xl font-bold mb-6 text-left pl-[5px] md:pl-0">
          My Resume
        </h1>
        <form
          onSubmit={handleSubmit(
            (data) => handleSubmitForm(data),
            (errs) => {
              console.log('Form validation errors:', errs);
              scrollToError(errs);
              toast({
                title: 'Please check your inputs',
                description: 'Some required fields need to be filled.',
                variant: 'destructive',
              });
            }
          )}
          className="space-y-4 text-left relative"
          noValidate
        >
          <PersonalInfoSection
            control={control}
            errors={errors}
            masterData={masterData}
          />

          <Section title="Educational Details">
            <div className="space-y-6">
              {formData.educations?.map((edu, index) => (
                <EducationFields
                  key={generateKeys.education(edu, index)}
                  education={edu}
                  index={index}
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  masterData={masterData}
                  onRemove={
                    (formData.educations?.length || 0) > 1
                      ? () => handleRemoveEducation(index)
                      : null
                  }
                />
              ))}
              <AddEducationButton onClick={handleAddEducation} />
            </div>
          </Section>

          <Section title="Work Experience">
            <div className="space-y-6">
              {formData.workExperiences?.map((exp, index) => (
                <WorkExperienceFields
                  key={generateKeys.workExperience(exp, index)}
                  experience={exp}
                  index={index}
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  onRemove={
                    (formData.workExperiences?.length || 0) > 1
                      ? () => handleRemoveWorkExperience(index)
                      : null
                  }
                />
              ))}
              <AddExperienceButton onClick={handleAddWorkExperience} />
            </div>
          </Section>

          <SkillsSection control={control} masterData={masterData} />
          <CertificationsSection control={control} masterData={masterData} />
          <FormActions isSubmitting={isSubmitting} />
        </form>
      </div>
    </div>
  );
}
