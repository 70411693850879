export const scrollToError = (errors) => {
  if (!errors || typeof errors !== 'object') return;

  const findFirstError = (obj, parentPath = '') => {
    if (Array.isArray(obj)) {
      return obj.reduce((foundError, item, index) => {
        if (foundError) return foundError;
        const fullPath = parentPath ? `${parentPath}.${index}` : `${index}`;
        return findFirstError(item, fullPath);
      }, null);
    }

    return Object.entries(obj).reduce((foundError, [key, value]) => {
      if (foundError) return foundError;

      const fullPath = parentPath ? `${parentPath}.${key}` : key;

      if (value && (value.type === 'required' || value.message)) {
        return fullPath;
      }

      if (value && typeof value === 'object') {
        return findFirstError(value, fullPath);
      }

      return foundError;
    }, null);
  };

  const firstError = findFirstError(errors);

  if (!firstError) return;

  setTimeout(() => {
    let element = document.querySelector(`[data-field="${firstError}"]`);

    // If not found directly, try to find parent container
    if (!element) {
      const parts = firstError.split('.');
      element = parts.reduce((foundElement, _, index) => {
        if (foundElement) return foundElement;
        const path = parts.slice(0, index + 1).join('.');
        return document.querySelector(`[data-field="${path}"]`);
      }, null);
    }

    if (element) {
      // Scroll into view with offset
      const yOffset = -100;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({
        top: y,
        behavior: 'smooth',
      });

      // Find focusable element
      const selectTrigger = element.querySelector('[role="combobox"]');
      const input = element.querySelector('input');
      const focusTarget = selectTrigger || input || element;

      // Focus after scroll completes
      setTimeout(() => {
        if (focusTarget && typeof focusTarget.focus === 'function') {
          focusTarget.focus();
        }

        // Add temporary highlight
        element.style.transition = 'all 0.3s ease';
        element.style.boxShadow = '0 0 0 2px rgb(239, 68, 68)';

        setTimeout(() => {
          element.style.boxShadow = 'none';
        }, 2000);
      }, 500);
    }
  }, 100);
};
